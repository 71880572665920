import { installationOrderService } from '@/api';
import { NormalSelectOptions } from '@/resource/model/common';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'StatusStep',
  components: {}
})
export default class StatusStep extends Vue {
  @Prop({ required: true, default: 1, type: Number })
  public active!: number;
  public installStatusList: NormalSelectOptions = [];
  public async stepStatusList(): Promise<void> {
    this.installStatusList = await installationOrderService.getStatusSelectableList();
  }

  public created(): void {
    this.stepStatusList();
  }
}
