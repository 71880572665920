import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { ShopItemReList } from '@/resource/model';
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'ProductDetails'
})
export default class ProductDetails extends mixins(PagingMixin) {
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return [];
    }
  })
  public products!: Array<ShopItemReList>;

  public productTableOptions: OsTableOption<Partial<ShopItemReList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public productColumnOptions: Array<OsTableColumn<ShopItemReList>> = [
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      fixed: true,
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).visibleWidth} × ${(row as ShopItemReList).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as ShopItemReList).finishWidth} × ${(row as ShopItemReList).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'addInstallationNotice.installCount',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public created(): void {
    this.products.forEach((item, index) => {
      item.index = index + 1;
    });
    this.pagingData();
  }

  public pagingData(): void {
    this.totalData = this.products.length;
    this.productTableOptions.data = this.products.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  @Watch('products')
  private handleDataChanged(): void {
    this.products.forEach((item, index) => {
      item.index = index + 1;
    });

    this.pagingData();
  }
}
