import { dateFormat } from '@/filters';
import { ExceptionRecordList } from '@/resource/model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'AcceptanceRecord',
  components: {}
})
export default class AcceptanceRecord extends Vue {
  @Prop({ required: true, default: [], type: Array })
  public exceptionList!: Array<ExceptionRecordList>;
  public acceptanceRecordList: Array<{ label: string; value: number; description: string }> = [];
  public stepStatusList(): void {
    this.acceptanceRecordList = [];
    this.exceptionList
      .filter(x => x.type === 2)
      .forEach(item => {
        this.acceptanceRecordList.push({
          label: `${dateFormat(item.createTime)}`,
          value: item.id,
          description: `${item.description}`
        });
      });
  }

  public created(): void {
    this.stepStatusList();
  }
  @Watch('exceptionList')
  private getApprovedLog(): void {
    this.stepStatusList();
  }
}
