import { FileService, installationOrderService, minioService } from '@/api';
import { UploadConfigParams, UploadedFile } from '@/resource/model';
import { dateFormat, downloadFileByBlob, messageError, messageErrors, translation } from '@/utils';
import { UploadFile } from '@/views/dialogs';
import { Message } from 'element-ui';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    UploadFile
  }
})
export default class InstallationOrderPhoto extends Vue {
  @Prop({
    required: true,
    type: Object,
    default() {
      return {
        title: '标题',
        getFileMethod: null,
        uploadFileMethod: null,
        fileTypeLimit: '.jpg,.jpeg,.png',
        fileSize: 20,
        fileLimit: 1,
        openDelete: false,
        openUpload: false
      };
    }
  })
  public uploadConfigParams!: UploadConfigParams;
  @Prop({
    required: true,
    type: Number
  })
  public orderId!: number;

  public previewUrls: Array<string> = [];
  public imgList: Array<{ id: number; url: string; businessFileId?: number }> = [];

  public uploadVisible = false;
  public downloadLoading = false;

  public loading: boolean = false;

  /**
   * 选中图片的数组
   */
  public checkImgList: Array<{ id: number; url: string; businessFileId: number }> = [];

  public deleteImage(): void {
    if (this.checkImgList.length === 0) {
      this.$message.info('请选择图片');
      return;
    }

    const fileIdList = this.checkImgList.map(x => x.businessFileId!);
    installationOrderService.deletePhoto(fileIdList).then(() => {
      this.checkImgList.forEach(item => {
        this.imgList.forEach((cur, index) => {
          if (item.id === cur.id) {
            this.imgList.splice(index, 1);
          }
        });
      });
      this.$message.info('删除成功');
      this.checkImgList = [];
    });
  }

  public created(): void {
    this.getFinishedPhotos();
    minioService.init();
  }

  @Watch('orderId')
  public reload(): void {
    this.$nextTick(() => {
      this.getFinishedPhotos();
    });
  }

  public async download(): Promise<void> {
    const fileList = this.imgList.map(x => x.id);

    if (fileList.length <= 0) {
      // Message.warning(translation('installationOrder.selectNewReceiptsTip'));
      Message.warning(translation('没有可下载文件'));
      return;
    }
    try {
      FileService.downloadZip(fileList)
        .then(blob => {
          downloadFileByBlob(`${this.uploadConfigParams.title}_${dateFormat(new Date())}.zip`, blob);
        })
        .catch(error => {
          messageError(error);
        })
        .finally(() => {});
    } catch (error) {
      messageErrors(error);
    }
  }

  public openUploadDialog(): void {
    this.uploadVisible = true;
  }

  public async handleUploaded(files: Array<UploadedFile>): Promise<void> {
    try {
      await this.uploadConfigParams.uploadFileMethod(
        this.orderId,
        files.map(x => x.id)
      );
      // this.loadPhotos(files);
      this.getFinishedPhotos();
      this.uploadVisible = false;
    } catch (error) {
      messageError(error);
    }
  }
  private async getFinishedPhotos(): Promise<void> {
    if (this.orderId === 0) {
      return;
    }
    this.loading = true;
    try {
      const photoFiles = await this.uploadConfigParams.getFileMethod(this.orderId);
      this.previewUrls = [];
      this.imgList = [];
      if (!photoFiles || photoFiles.length === 0) {
        return;
      }
      photoFiles.forEach((x: any) => {
        this.imgList.push({ id: x.id, url: x.url, businessFileId: x.businessFileId });
        this.previewUrls.push(x.url);
      });
      // await this.loadPhotos(photoFiles);
    } catch (error) {
      messageError(error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * 加载照片
   * @param photoFiles 文件
   */
  private async loadPhotos(photoFiles: Array<UploadedFile>): Promise<void> {
    try {
      const previewUrlsRequest: Array<Promise<{ url: string; id: number }>> = photoFiles.map(async x => {
        const url = await minioService.getDownLoadPath(x.path);
        return Promise.resolve({ id: x.id, url: url });
      });
      this.imgList.push(...(await Promise.all(previewUrlsRequest)));
      this.previewUrls = this.imgList.map(x => x.url);
    } catch (error) {
      messageError(error);
    }
  }
}
