import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { ExceptionRecordList } from '@/resource/model';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { dateFormat } from '@/utils';

@Component({
  components: {}
})
export default class ExceptionRecord extends Vue {
  @Prop({
    required: true,
    type: Array,
    default: []
  })
  public exceptionList!: Array<ExceptionRecordList>;

  /**
   * 表格工具栏配置
   */
  public operationOptions: Array<OperationOption> = [];

  public tableOptions: OsTableOption<ExceptionRecordList> = {
    loading: false,
    data: [],
    fit: true,
    size: 'small',
    border: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<ExceptionRecordList>> = [
    {
      prop: 'index',
      label: 'common.index',
      minWidth: '50px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'createTime',
      label: 'brokenLog.createTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      fixed: true,
      formatter: (rowData: Object): string => {
        return `${dateFormat((rowData as ExceptionRecordList).createTime)}`;
      }
    },
    {
      prop: 'description',
      label: 'installationOrder.exceptionExplain',
      minWidth: '200px',
      showOverflowTooltip: true
    },
    {
      prop: 'fileList',
      label: 'billing.attachment',
      minWidth: '500px',
      showOverflowTooltip: true
    }
  ];

  public created(): void {
    this.handleExceptionRecordList();
  }

  public handleImageUrlList(fileList: Array<{ id: number; url: string }>): Array<string> {
    return fileList.map(x => x.url!);
  }

  public handleExceptionRecordList(): void {
    const list = this.exceptionList.filter(x => x.type === 1);
    list.forEach((item, index) => {
      item.index = index + 1;
    });
    this.tableOptions.data = list;
  }

  @Watch('exceptionList', { immediate: true, deep: true })
  private getExceptionList(): void {
    this.handleExceptionRecordList();
  }
}
